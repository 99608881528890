import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import techBackground from '../images/tech-background.jpg';
import creativeBackground from '../images/creative-background.jpg';
import spiritualBackground from '../images/spiritual-background.jpg';
import { PT, GB, IN } from 'country-flag-icons/react/3x2';

const Home: React.FC = () => {
  const [hoveredCard, setHoveredCard] = useState<string | null>(null);

  const cards = [
    { 
      path: "/tech", 
      title: "Tech", 
      subtitle: "Software Engineering & Fintech", 
      background: techBackground,
      overlayText: "Explore my tech projects and skills in software development and financial technology."
    },
    { 
      path: "/creative-arts", 
      title: "Creative Arts", 
      subtitle: "Music Production, Arts & Game Development", 
      background: creativeBackground,
      overlayText: "Discover my creative endeavors in music, art, and game development."
    },
    { 
      path: "/spirituality", 
      title: "Spirituality", 
      subtitle: "Meditation, Yoga & More", 
      background: spiritualBackground,
      overlayText: "Explore my spiritual journey through meditation, yoga, and other practices."
    },
  ];

  return (
    <div className="home-container">
      <h1 className="main-title">Welcome to My Portfolio</h1>
      <div className="bio">
        <p className="bio-line">Tomás Soares | 25 years</p>
        <p className="bio-line">
          Lisbon, Porto <PT title="Portugal" className="flag-icon" /> | 
          London <GB title="United Kingdom" className="flag-icon" /> | 
          Goa <IN title="India" className="flag-icon" />
        </p>
        <p className="bio-line">Senior Developer | Musician | CEO | Yogi | Digital Nomad</p>
      </div>
      <div className="card-container">
        {cards.map((card) => (
          <Link
            key={card.path}
            to={card.path}
            className={`card ${card.title.toLowerCase()}-card ${hoveredCard === card.path ? 'hovered' : ''}`}
            onMouseEnter={() => setHoveredCard(card.path)}
            onMouseLeave={() => setHoveredCard(null)}
          >
            <div className="card-content" style={{backgroundImage: `url(${card.background})`}}>
              <h2>{card.title}</h2>
              <p>{card.subtitle}</p>
              <div className="card-overlay">
                <p>{card.overlayText}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Home;