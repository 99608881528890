import React from 'react';
import { FaReact, FaNodeJs, FaPython, FaAws, FaDocker, FaAndroid, FaAtlassian, FaDigitalOcean, FaEthereum, FaEnvelope, FaFileWord, FaFigma, FaGit, FaGithub, FaGitkraken, FaGitlab, FaGulp, FaJava, FaJenkins, FaJira, FaJs, FaKaggle, FaLinux, FaMendeley, FaNpm, FaPhp, FaRaspberryPi, FaRProject, FaSass, FaSlack, FaSourcetree, FaStackOverflow, FaTrello, FaVuejs, FaWindows, FaYarn, FaBtc, FaUserTie, FaChartBar, FaChartLine, FaChartArea, FaExchangeAlt, FaDatabase, FaNetworkWired, FaServer, FaDesktop, FaMobile, FaCode, FaCogs, FaProjectDiagram, FaLaptopCode, FaFileCode, FaTerminal, FaFileExcel, FaApple } from 'react-icons/fa';
import { SiTensorflow, SiTypescript, SiMysql, SiUnity, SiDotnet, SiUpwork, SiMarkdown, SiCisco, SiMongodb, SiCsharp, SiAmazonapigateway, SiAwslambda, SiCplusplus, SiApachemaven, SiC, SiHtml5, SiCss3, SiWebgl, SiThreedotjs, SiBlender, SiAdobephotoshop, SiVisualstudiocode, SiPostgresql, SiMicrosoftsqlserver, SiServerless, SiAmazondynamodb, SiAmazonec2, SiAmazons3, SiAmazoncloudwatch, SiAmazonroute53, SiAmazoneks, SiKubernetes, SiRedis, SiElasticsearch, SiGraphql, SiApollographql, SiNestjs, SiExpress, SiSpring, SiDjango, SiFlask, SiLaravel, SiRubyonrails, SiAngular, SiNextdotjs, SiNuxtdotjs, SiSvelte, SiElectron, SiTailwindcss, SiBootstrap, SiMaterialdesign, SiStyledcomponents, SiWebpack, SiBabel, SiEslint, SiPrettier, SiJest, SiMocha, SiChai, SiCypress, SiSelenium, SiPostman, SiSwagger, SiNginx, SiApache, SiRedhat, SiUbuntu, SiDebian, SiCentos, SiFreebsd, SiOpenbsd, SiNetbsd, SiAndroidstudio, SiXcode, SiEclipseide, SiIntellijidea, SiPycharm, SiWebstorm, SiPhpstorm, SiRubymine, SiGoland, SiClion, SiRider, SiDatagrip, SiTableau, SiPowerbi, SiGrafana, SiKibana, SiPrometheus, SiInfluxdb, SiTerraform, SiAnsible, SiPuppet, SiChef, SiVagrant, SiHeroku, SiNetlify, SiVercel, SiFirebase, SiGooglecloud, SiMicrosoftazure, SiDigitalocean, SiVultr, SiHetzner, SiScaleway, SiOvh, SiCloudflare, SiAuth0, SiOkta, SiKeycloak, SiStripe, SiPaypal, SiSquare, SiTwilio, SiMailchimp, SiAlgolia, SiElasticstack, SiSentry, SiNewrelic, SiDatadog, SiMixpanel, SiGoogleanalytics, SiHotjar, SiIntercom, SiZendesk, SiJirasoftware, SiConfluence, SiBitbucket, SiAsana, SiNotion, SiAirtable, SiFigma, SiSketch, SiAdobexd, SiInvision, SiMiro, SiFramer, SiStorybook, SiChromatic, SiJavascript, SiOpenjdk, SiBinance, SiTradingview, SiPinescript, SiCanva, SiJupyter, SiOpenai, SiGooglegemini, SiAnthropic, SiHostinger } from 'react-icons/si';
import { TbBrandReactNative, TbBrandKotlin, TbBrandSwift, TbBrandFlutter, TbBrandXamarin  } from 'react-icons/tb';
import { DiProlog } from 'react-icons/di';
import { GiProcessor } from 'react-icons/gi';
import { AiFillApi } from 'react-icons/ai';

export const getTechIcon = (techName: string): React.ReactElement => {
  const iconMap: { [key: string]: React.ReactElement } = {
    'React': <FaReact />,
    'Node.js': <FaNodeJs />,
    'Python': <FaPython />,
    'AWS': <FaAws />,
    'Docker': <FaDocker />,
    'Bitcoin': <FaBtc />,
    'TensorFlow': <SiTensorflow />,
    'TypeScript': <SiTypescript />,
    'MySQL': <SiMysql />,
    'Unity': <SiUnity />,
    '.NET': <SiDotnet />,
    'Android': <FaAndroid />,
    'GitHub': <FaGithub />,
    'Slack': <FaSlack />,
    'Figma': <FaFigma />,
    'Windows': <FaWindows />,
    'Stack Overflow': <FaStackOverflow />,
    'Java': <FaJava />,
    'Linux': <FaLinux />,
    'GitLab': <FaGitlab />,
    'Atlassian': <FaAtlassian />,
    'Yarn': <FaYarn />,
    'Vue.js': <FaVuejs />,
    'Trello': <FaTrello />,
    'Sourcetree': <FaSourcetree />,
    'Sass': <FaSass />,
    'Raspberry Pi': <FaRaspberryPi />,
    'R': <FaRProject />,
    'PHP': <FaPhp />,
    'npm': <FaNpm />,
    'Mendeley': <FaMendeley />,
    'MongoDB': <SiMongodb />,
    'Markdown': <SiMarkdown />,
    'JavaScript': <FaJs />,
    'Kaggle': <FaKaggle />,
    'Jira': <FaJira />,
    'Jenkins': <FaJenkins />,
    'Gulp': <FaGulp />,
    'GitKraken': <FaGitkraken />,
    'Git': <FaGit />,
    'Ethereum': <FaEthereum />,
    'DigitalOcean': <FaDigitalOcean />,
    'Person': <FaUserTie />,
    'Trading View': <SiTradingview  />,
    'Trading View Library': <SiTradingview  />,
    'Sierra Charts': <FaChartArea />,
    'Atas': <FaChartBar />,
    'Metatrader': <FaChartLine />,
    'C#': <SiCsharp />,
    'C++': <SiCplusplus />,
    'C': <SiC />,
    'HTML': <SiHtml5 />,
    'CSS': <SiCss3 />,
    'WebGL': <SiWebgl />,
    'Three.js': <SiThreedotjs />,
    'Prolog': <DiProlog  />,
    'Maven': <SiApachemaven />,
    'Blender': <SiBlender />,
    'Photoshop': <SiAdobephotoshop />,
    'Visual Studio Code': <SiVisualstudiocode />,
    'Cursor': <SiVisualstudiocode />,
    'PostgreSQL': <SiPostgresql />,
    'MSSQL': <SiMicrosoftsqlserver />,
    'Serverless Framework': <SiServerless />,
    'DynamoDB': <SiAmazondynamodb />,
    'EC2': <SiAmazonec2 />,
    'Claude': <SiAnthropic />,
    'Anthropic': <SiAnthropic />,
    'Gemini': <SiGooglegemini />, 
    'ChatGPT': <SiOpenai />, 
    'S3': <SiAmazons3 />,
    'CloudWatch': <SiAmazoncloudwatch />,
    'Route 53': <SiAmazonroute53 />,
    'EKS': <SiAmazoneks />,
    'Kubernetes': <SiKubernetes />,
    'Redis': <SiRedis />,
    'Elasticsearch': <SiElasticsearch />,
    'GraphQL': <SiGraphql />,
    'Apollo': <SiApollographql />,
    'Jupyter': <SiJupyter />,
    'NestJS': <SiNestjs />, 
    'Express': <SiExpress />,
    'Spring': <SiSpring />,
    'Django': <SiDjango />,
    'Flask': <SiFlask />,
    'Laravel': <SiLaravel />,
    'Ruby on Rails': <SiRubyonrails />,
    'Angular': <SiAngular />,
    'Next.js': <SiNextdotjs />,
    'Nuxt.js': <SiNuxtdotjs />,
    'Svelte': <SiSvelte />,
    'Electron': <SiElectron />,
    'Tailwind CSS': <SiTailwindcss />,
    'Bootstrap': <SiBootstrap />,
    'Material-UI': <SiMaterialdesign />,
    'Styled Components': <SiStyledcomponents />,
    'Webpack': <SiWebpack />,
    'Babel': <SiBabel />,
    'ESLint': <SiEslint />,
    'Prettier': <SiPrettier />,
    'Jest': <SiJest />,
    'Mocha': <SiMocha />,
    'Chai': <SiChai />,
    'Cypress': <SiCypress />,
    'Selenium': <SiSelenium />,
    'Postman': <SiPostman />,
    'Swagger': <SiSwagger />,
    'Nginx': <SiNginx />,
    'Apache': <SiApache />,
    'Red Hat': <SiRedhat />,
    'Ubuntu': <SiUbuntu />,
    'Debian': <SiDebian />,
    'CentOS': <SiCentos />,
    'FreeBSD': <SiFreebsd />,
    'OpenBSD': <SiOpenbsd />,
    'NetBSD': <SiNetbsd />,
    'Android Studio': <SiAndroidstudio />,
    'Xcode': <SiXcode />,
    'Eclipse': <SiEclipseide />,
    'IntelliJ IDEA': <SiIntellijidea />,
    'PyCharm': <SiPycharm />,
    'WebStorm': <SiWebstorm />,
    'PhpStorm': <SiPhpstorm />,
    'RubyMine': <SiRubymine />,
    'GoLand': <SiGoland />,
    'CLion': <SiClion />,
    'Rider': <SiRider />,
    'DataGrip': <SiDatagrip />,
    'Tableau': <SiTableau />,
    'Power BI': <SiPowerbi />,
    'Grafana': <SiGrafana />,
    'Kibana': <SiKibana />,
    'Prometheus': <SiPrometheus />,
    'InfluxDB': <SiInfluxdb />,
    'Terraform': <SiTerraform />,
    'Ansible': <SiAnsible />,
    'Puppet': <SiPuppet />,
    'Chef': <SiChef />,
    'Vagrant': <SiVagrant />,
    'Heroku': <SiHeroku />,
    'Netlify': <SiNetlify />,
    'Vercel': <SiVercel />,
    'Firebase': <SiFirebase />,
    'Google Cloud': <SiGooglecloud />,
    'Azure': <SiMicrosoftazure />,
    'Vultr': <SiVultr />,
    'Hetzner': <SiHetzner />,
    'Scaleway': <SiScaleway />,
    'OVH': <SiOvh />,
    'Cloudflare': <SiCloudflare />,
    'Auth0': <SiAuth0 />,
    'Okta': <SiOkta />,
    'Keycloak': <SiKeycloak />,
    'Stripe': <SiStripe />,
    'PayPal': <SiPaypal />,
    'Square': <SiSquare />,
    'Twilio': <SiTwilio />,
    'Mailchimp': <SiMailchimp />,
    'Algolia': <SiAlgolia />,
    'Elastic Stack': <SiElasticstack />,
    'Sentry': <SiSentry />,
    'New Relic': <SiNewrelic />,
    'Datadog': <SiDatadog />,
    'Mixpanel': <SiMixpanel />,
    'Google Analytics': <SiGoogleanalytics />,
    'Hotjar': <SiHotjar />,
    'Intercom': <SiIntercom />,
    'Zendesk': <SiZendesk />,
    'Jira Software': <SiJirasoftware />,
    'Confluence': <SiConfluence />,
    'Bitbucket': <SiBitbucket />,
    'Asana': <SiAsana />,
    'Notion': <SiNotion />,
    'Airtable': <SiAirtable />,
    'Sketch': <SiSketch />,
    'Adobe XD': <SiAdobexd />,
    'InVision': <SiInvision />,
    'Miro': <SiMiro />,
    'Framer': <SiFramer />,
    'Storybook': <SiStorybook />,
    'Chromatic': <SiChromatic />,
    'React Native': <TbBrandReactNative />,
    'Kotlin': <TbBrandKotlin />,
    'Swift': <TbBrandSwift />,
    'Flutter': <TbBrandFlutter />,
    'Visual Paradigm': <FaProjectDiagram />,
    'Bybit': <FaExchangeAlt />,
    'Excel': <FaFileExcel />,
    'iOS': <FaApple />, 
    'JavaFX': <SiOpenjdk />,
    'Swing': <FaJava  />,
    'CiscoOS': <SiCisco />,
    'VPNs': <FaNetworkWired />,
    'Routing': <FaNetworkWired />,
    'Hostinger': <SiHostinger />,
    'Switching': <FaNetworkWired />,
    'Futures': <FaChartLine />,
    'Decentralized Trading': <FaExchangeAlt />,
    'Lambda ': <SiAwslambda />,
    'Lambda': <SiAwslambda />,
    'Lambda Layers': <SiAwslambda />,
    'ORM': <FaDatabase />,
    'Typeorm': <FaDatabase />,
    'Shell/Bash': <FaTerminal />,
    'REST APIs': <AiFillApi />,
    'WebApps': <FaLaptopCode />,
    'Assembly': <GiProcessor />,
    'MQL4': <FaFileCode />,
    'MQL5': <FaFileCode />,
    'Pinescript': <SiPinescript  />,
    'Binance': <SiBinance  />,
    'Yoti': <FaUserTie />,
    'Docusign': <FaFileCode />,
    'Upwork': <SiUpwork />,
    'Mailparser': <FaEnvelope />,
    'Docxtemplater': <FaFileWord />,
    'Unity Ads': <SiUnity />,
    'Xamarin': <TbBrandXamarin />,
    'API Gateway': <SiAmazonapigateway />, 
    '3D Modeling': <SiBlender />,
    'Canva': <SiCanva />
  };

  // Function to find the best matching icon
  const findBestMatch = (name: string): React.ReactElement => {
    name = name.toLowerCase();
    let bestMatch = '';
    let bestMatchScore = 0;

    for (const key of Object.keys(iconMap)) {
      const keyLower = key.toLowerCase();
      if (name === keyLower) {
        return iconMap[key]; // Exact match, return immediately
      }

      // Calculate a match score based on the longest common substring
      const score = longestCommonSubstring(name, keyLower);
      if (score > bestMatchScore) {
        bestMatchScore = score;
        bestMatch = key;
      }
    }

    // Only return a match if it's significant (e.g., more than 3 characters)
    return bestMatchScore > 3 ? iconMap[bestMatch] : <FaCogs />;
  };

  // Helper function to find the length of the longest common substring
  const longestCommonSubstring = (str1: string, str2: string): number => {
    const m = str1.length;
    const n = str2.length;
    const dp: number[][] = Array(m + 1).fill(null).map(() => Array(n + 1).fill(0));
    let maxLength = 0;

    for (let i = 1; i <= m; i++) {
      for (let j = 1; j <= n; j++) {
        if (str1[i - 1] === str2[j - 1]) {
          dp[i][j] = dp[i - 1][j - 1] + 1;
          maxLength = Math.max(maxLength, dp[i][j]);
        }
      }
    }

    return maxLength;
  };

  // First, try to find an exact match
  if (iconMap[techName]) {
    return iconMap[techName];
  }

  // If no exact match, try to find a partial match
  return findBestMatch(techName);
};