import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import './CreativeArts.css';
import creativeArtsData from '../data/creativeArtsProjects.json';
import { FaGuitar, FaMicrophone, FaMusic, FaPlug, FaKeyboard, FaMicrochip, 
         FaInstagram, FaYoutube, FaBandcamp, FaTiktok, FaSoundcloud, 
         FaTwitter, FaDiscord, FaDesktop, FaCogs } from 'react-icons/fa';
import { PiPianoKeysFill } from "react-icons/pi";
import { SiTiktok, SiSoundcloud, SiYoutube } from 'react-icons/si';

interface MediaItem {
  type: string;
  src?: string;
  content?: string;
  description?: string;
}

interface Skill {
  name: string;
  years: number;
  icon: string;
}

interface Tool {
  name: string;
  icon: string;
}

interface ToolCategory {
  name: string;
  tools: Tool[];
}

interface SocialLink {
  platform: string;
  url: string;
}

interface MusicSection {
  title: string;
  media: MediaItem[];
  skills?: Skill[];
  toolCategories?: ToolCategory[];
  socialLinks?: SocialLink[];
  education?: string[] | string;
}

interface InstagramEmbed {
  html: string;
}

declare global {
  interface Window {
    instgrm?: {
      Embeds: {
        process(): void;
      };
    };
  }
}

const iconMap: { [key: string]: React.ElementType } = {
  FaGuitar,
  FaMicrophone,
  FaMusic,
  PiPianoKeysFill,
  FaDesktop,
  FaCogs,
  FaPlug,
  FaKeyboard,
  FaMicrochip,
  FaInstagram,
  FaBandcamp,
  FaTwitter,
  FaDiscord,
  FaYoutube: SiYoutube,
  FaTiktok: SiTiktok,
  FaSoundcloud: SiSoundcloud
};

const CreativeArts: React.FC = () => {
  const [sections, setSections] = useState<MusicSection[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setSections(creativeArtsData.sections as MusicSection[]);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (window.instgrm) {
        window.instgrm.Embeds.process();
        setIsLoading(false);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [sections]);

  const openModal = (imageSrc: string) => {
    setSelectedImage(imageSrc);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const renderToolCategories = (categories: ToolCategory[]) => {
    return (
      <div className="tools-grid">
        {categories.flatMap((category) =>
          category.tools.map((tool, toolIndex) => {
            const Icon = iconMap[tool.icon];
            return (
              <div key={`${category.name}-${toolIndex}`} className="tool-item">
                {Icon && <Icon />}
                <span>{tool.name}</span>
              </div>
            );
          })
        )}
      </div>
    );
  };

  const renderSocialLinks = (links: SocialLink[]) => {
    const getIcon = (platform: string) => {
      if (platform === 'YouTube') return SiYoutube;
      if (platform === 'TikTok') return SiTiktok;
      if (platform === 'SoundCloud') return SiSoundcloud;
      return iconMap[`Fa${platform.replace(/\s+/g, '')}`];
    };

    return (
      <div className="social-links">
        <h3>Social Media</h3>
        <div className="social-icons">
          {links.map((link, index) => {
            const Icon = getIcon(link.platform);
            return (
              <a 
                key={index} 
                href={link.url} 
                target="_blank" 
                rel="noopener noreferrer"
                className="social-icon"
              >
                {Icon && <Icon />}
              </a>
            );
          })}
        </div>
      </div>
    );
  };

  const renderMusicInfo = (section: MusicSection) => {
    return (
      <div className="music-info">
        <div className="music-skills">
          {section.skills?.map((skill, index) => {
            const Icon = iconMap[skill.icon];
            return (
              <div key={index} className="skill-item">
                {Icon && <Icon />}
                <span>{skill.name} ({skill.years} years)</span>
              </div>
            );
          })}
        </div>
        
        {section.toolCategories && renderToolCategories(section.toolCategories)}
        {section.socialLinks && renderSocialLinks(section.socialLinks)}
        
        {section.education && (
          <div className="music-education">
            <h3>Education</h3>
            {Array.isArray(section.education) ? (
              section.education.map((edu, index) => (
                <p key={index}>{edu}</p>
              ))
            ) : (
              <p>{section.education}</p>
            )}
          </div>
        )}
      </div>
    );
  };

  const getInstagramEmbed = async (url: string) => {
    try {
      const response = await fetch(
        `https://api.instagram.com/oembed/?url=${encodeURIComponent(url)}&omitscript=true`
      );
      const data: InstagramEmbed = await response.json();
      return data.html;
    } catch (error) {
      console.error('Error fetching Instagram embed:', error);
      return null;
    }
  };

  const loadingStyle = {
    display: isLoading ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    color: '#af5efa'
  };

  return (
    <div className="creative-arts-container">
      <h1>Creative Arts</h1>
      <Link to="/" className="ca-home-link">Go to Homepage</Link>
      {sections.map((section, index) => (
        <section key={index} className="creative-arts-section">
          <h2>{section.title}</h2>
          {section.media.map((mediaItem, mediaIndex) => (
            mediaItem.type === 'text' && (
              <p key={mediaIndex}>{mediaItem.content}</p>
            )
          ))}
          {section.title === "Music Production" && renderMusicInfo(section)}
          <div className={`media-container ${section.title === 'Digital Painting' ? 'digital-painting-grid' : ''}`}>
            {section.media.map((mediaItem, mediaIndex) => (
              mediaItem.type !== 'text' && (
                <div key={mediaIndex} className="media-item">
                  {mediaItem.type === 'video' && (
                    <iframe
                      width="560"
                      height="315"
                      src={mediaItem.src}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  )}
                  {mediaItem.type === 'image' && (
                    <img
                      src={mediaItem.src}
                      alt={mediaItem.description}
                      onClick={() => openModal(mediaItem.src!)}
                    />
                  )}
                  {mediaItem.type === 'instagram' && (
                    <div className="instagram-embed-container">
                      <div style={loadingStyle}>Loading Instagram content...</div>
                      <div 
                        style={{ display: isLoading ? 'none' : 'block', height: '100%', width: '100%' }}
                        dangerouslySetInnerHTML={{ 
                          __html: `<blockquote 
                            class="instagram-media" 
                            data-instgrm-captioned
                            data-instgrm-permalink="${mediaItem.src}"
                            data-instgrm-version="14"
                            style="
                              background:#FFF; 
                              border:0; 
                              border-radius:3px; 
                              box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15); 
                              margin: 1px; 
                              min-width:326px; 
                              padding:0; 
                              width:100%; 
                              width:-webkit-calc(100%); 
                              width:calc(100%);"
                          ></blockquote>`
                        }}
                      />
                    </div>
                  )}
                </div>
              )
            ))}
          </div>
        </section>
      ))}

      <AnimatePresence>
        {selectedImage && (
          <motion.div
            className="modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeModal}
          >
            <motion.img
              src={selectedImage}
              alt="Full-screen image"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              onClick={(e) => e.stopPropagation()}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CreativeArts;