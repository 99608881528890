import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Spirituality.css';
import spiritualityData from '../data/spiritualityProjects.json';

interface MediaItem {
  type: string;
  src?: string;
  content?: string;
  description?: string;
}

interface Section {
  title: string;
  media: MediaItem[];
}

const Spirituality: React.FC = () => {
  const [sections, setSections] = useState<Section[]>([]);

  useEffect(() => {
    // Fetch the data from the JSON file
    setSections(spiritualityData.sections);
  }, []);

  return (
    <div className="spirituality-container">
      <h1>Spirituality</h1>
      <Link to="/" className="sp-home-link">Go to homepage</Link>
      {sections.map((section, index) => (
        <section key={index} className="spirituality-section">
          <h2>{section.title}</h2>
          {section.media.map((mediaItem, mediaIndex) => (
            mediaItem.type === 'text' && (
              <p key={mediaIndex}>{mediaItem.content}</p>
            )
          ))}
          <div className="spirituality-media-container">
            {section.media.map((mediaItem, mediaIndex) => (
              mediaItem.type !== 'text' && (
                <div key={mediaIndex} className="spirituality-media-item">
                  {mediaItem.type === 'video' && (
                    <iframe
                      width="560"
                      height="315"
                      src={mediaItem.src}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  )}
                  {mediaItem.type === 'image' && (
                    <img src={mediaItem.src} alt={mediaItem.description} />
                  )}
                </div>
              )
            ))}
          </div>
        </section>
      ))}
    </div>
  );
};

export default Spirituality;