import React from 'react';
import { HashRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './components/Home';
import Tech from './components/Tech';
import CreativeArts from './components/CreativeArts';
import Spirituality from './components/Spirituality';
import sacredGeometry from './images/sacred-geometry-pattern.avif';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tech" element={<Tech />} />
          <Route path="/creative-arts" element={<CreativeArts />} />
          <Route path="/spirituality" element={<Spirituality />} />
        </Routes>
        <div className="sacred-geometry" style={{backgroundImage: `url(${sacredGeometry})`}}></div>
      </div>
    </Router>
  );
}

export default App;